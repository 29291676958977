import {Panel} from "primereact/panel";
import React, {useRef} from "react";


import {Prodotto} from "../../models/Prodotto";
import {Fieldset} from "primereact/fieldset";
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import {Button} from "primereact/button";
import {InputNumber, InputNumberProps} from "primereact/inputnumber";
import {FloatLabel} from "primereact/floatlabel";


export const ProdottoView = (props:Prodotto) =>{

    return (
        <Panel  style={{marginTop:"1em"}}  header={props.product_name}>
            <ProdottoForm {...props} />
        </Panel >
    )

}

export const ProdottoForm = (props:Prodotto) =>{
    const toast = useRef(null);
    const handleChange = (event:React.ChangeEvent<HTMLInputElement>)=>{
        event.preventDefault();
        // @ts-ignore
        //toast.current.show({ severity: 'info', summary: 'Save', detail: 'Prodotto Salvato' });
        //event.currentTarget.value
    }

    const saveData = ()=>{
        // @ts-ignore
        toast.current.show({ severity: 'info', summary: 'Save', detail: 'Prodotto Salvato' });
    }

  return (
      <>
          <Toast ref={toast} />
          <FloatLabel >
              <label style={{marginTop:"1em"}} htmlFor="username">Nome Prodotto</label>
              <InputText style={{marginTop:"1em"}} id={"product_name"} value={props.product_name} onChange={handleChange}/>
          </FloatLabel>
          <FloatLabel>
              <label style={{marginTop:"2em"}} htmlFor="costo">Costo</label>
              <InputNumber style={{marginTop:"2em"}} id="costo" value={props.costo} onChange={(event: InputNumberProps) => {
              }}/>
          </FloatLabel>
          <FloatLabel>
              <label style={{marginTop:"2em"}} htmlFor="price">Prezzo</label>
              <InputNumber style={{marginTop:"2em"}} id="price" value={props.price} onChange={(event: InputNumberProps) => {
              }}/>
          </FloatLabel>
          <FloatLabel>
              <label style={{marginTop:"2em"}} htmlFor="type">Type</label>
              <InputText style={{marginTop:"2em"}} id="type" value={props.type} onChange={(event: InputNumberProps) => {
              }}/>
          </FloatLabel>
          <Button style={{marginTop:"2em"}} label="Success" severity="success" onClick={saveData} />

      </>
  )

}


export class ProdottoFormOld extends React.Component {
    constructor(props: Prodotto) {
        super(props);
        this.state = {value: ''};

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange = (event: React.FormEvent<HTMLInputElement>) => {
        this.setState({value: ''});
    }

    handleSubmit = (event:React.FormEvent<HTMLInputElement>)  => {
        alert('A name was submitted: ');
        event.preventDefault();
    }

    render() {
        return (
            <form >
                <label>
                    Name:
                    <input type="text" value={""} onChange={this.handleChange} />
                </label>
                <input type="submit" value="Submit" />
            </form>
        );
    }
}