import axios from "axios";
import {Prodotto} from "../models/Prodotto";
import config from "../config";
import {Ordine} from "../pages/prenotazioni/ordini";

export const  OrderService = async () => {
    let token = sessionStorage.getItem('token')
    axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    try {
        const response = await axios.get<Ordine[]>(`${config.API_URL}/ordine`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            },
        });
        console.log(response.data);
        return response.data;
    } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
            // Handle known error responses from the server
            //setError(`Error: ${err.response.status} - ${err.response.statusText}`);
        } else {
            // Handle unexpected errors
            //setError('An unexpected error occurred');
        }
    }
};