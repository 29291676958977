import React from "react";
import {Alignment, Button, Navbar} from "@blueprintjs/core";

export default  class FattureApp extends React.PureComponent {
    public render() {

        return(
            <>
                <Navbar>
                    <Navbar.Group align={Alignment.LEFT}>
                        <Navbar.Heading>Fatture</Navbar.Heading>
                        <Navbar.Divider />
                        <Button className="bp5-minimal" icon="document" text="Ordini" />
                        <Button className="bp5-minimal" icon="document" text="Fatture Emesse" />
                        <Button className="bp5-minimal" icon="document" text="Corrispettivi" />
                        <Button className="bp5-minimal" icon="document" text="Rimborsi" />

                        <Button className="bp5-minimal" icon="document" text="Transazioni Sumup" />
                        <Button className="bp5-minimal" icon="document" text="Transazioni Stripe" />

                    </Navbar.Group>
                </Navbar>
            </>
        )

    }
}